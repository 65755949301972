<template>
  <div class="inventory-show">
    <v-container fluid>
      <div class="welcome">
        <div class="left">
          <v-btn @click.prevent.native="goBack" icon color="blue">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <h1>{{ title }}</h1>

          <!-- Status badge -->
          <v-chip v-if="product" class="ml-5" color="primary">{{
            getHumanReadableStatus(product.status)
          }}</v-chip>
          <!-- / Status badge -->
        </div>
        <div class="right" v-if="product">
          <delete-product-button :product="product" @product-deleted="handleProductDeleted"
            class="mr-3"></delete-product-button>

          <edit-images-button-and-modal class="mr-3" @refresh="fetchSilently"
            :product="product"></edit-images-button-and-modal>

          <change-product-status-button-and-modals @refresh="fetchSilently"
            :product="product"></change-product-status-button-and-modals>
        </div>
      </div>

      <!-- Do some loading thing here -->
      <div class="loading" v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <!-- / Do some loading thing here -->

      <v-card v-if="!isLoading && product">
        <v-tabs v-model="selectedTab">
          <v-tab>Bag Details</v-tab>
          <v-tab>
            {{ product.account_id ? "Consignor Details" : "Assign Consignor Directly" }}
          </v-tab>

          <!-- <v-tab v-if="product.account_id">Consignor Details</v-tab> -->
          <v-tab v-if="product.account_id">Invoices</v-tab>

          <v-tab v-if="!product.account_id">
            {{
              product.offers && product.offers.length
              ? "Offers Extended"
              : "Extend Offer"
            }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <!-- Product Details -->
          <v-tab-item class="pt-2">
            <v-row>
              <v-col :cols="12" :md="6" class="mt-10">
                <product-images-gallery :key="`images-${silentUpdateKey}`" :product="product"></product-images-gallery>
              </v-col>
              <v-col :cols="12" :md="6">
                <div class="pe-5">
                  <!-- <v-card-text>
                    <edit-images-button-and-modal
                      @refresh="fetchSilently"
                      :product="product"
                    ></edit-images-button-and-modal>
                  </v-card-text> -->

                  <!-- <base-card> -->
                  <!-- <v-card-title>Product Details</v-card-title> -->
                  <v-card-text :key="`basic-details-${silentUpdateKey}`">
                    <h5>Basic Details</h5>
                    <!-- Name -->
                    <div class="product-property">
                      <editable-field property="name" name="Name" :initial-value="product.name" :endpoint="endpoint"
                        type="text" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Name -->
                    <!-- Stock Number -->
                    <div class="product-property">
                      <editable-field property="stock_number" name="Stock Purchase Number" :initial-value="product.stock_number"
                        :endpoint="endpoint" type="text" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Stock Number -->
                    <!-- Sales Number -->
                    <div class="product-property">
                      <editable-field property="sales_number" name="Sales Invoice Number" :initial-value="product.sales_number"
                                      :endpoint="endpoint" type="text" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Sales Number -->
                    <!-- Country of Export -->
                    <div class="product-property">
                      <editable-field property="country_of_export" name="Country of Export" :initial-value="product.country_of_export"
                                      :endpoint="endpoint" type="text" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Country of Export-->
                    <!-- Description -->
                    <!-- <div class="product-property">
                      <editable-field
                        property="description"
                        name="Description"
                        :initial-value="product.description"
                        :endpoint="endpoint"
                        type="text"
                        @updated="fetchSilently"
                      ></editable-field>
                    </div> -->
                    <!-- / Description -->
                    <!-- Location -->
                    <div class="product-property">
                      <editable-field property="location_id" name="Location" :initial-value="product.location_id"
                        :endpoint="endpoint" type="select" :options="locationDropdownOptions"
                        @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Location -->
                  </v-card-text>
                  <v-card-text :key="`additional-properties-${silentUpdateKey}`">
                    <h5>Additional Properties</h5>
                    <!-- Colour -->
                    <div class="product-property">
                      <editable-field property="bag_colour_id" name="Colour" :initial-value="product.bag_colour_id"
                        :endpoint="endpoint" type="select" :options="bagColourDropdownOptions"
                        @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Colour -->

                    <!-- Condition -->
                    <div class="product-property">
                      <editable-field property="bag_condition_id" name="Condition"
                        :initial-value="product.bag_condition_id" :endpoint="endpoint" type="select"
                        :options="bagConditionDropdownOptions" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Condition -->

                    <!-- Style -->
                    <div class="product-property">
                      <editable-field property="bag_style_id" name="Style" :initial-value="product.bag_style_id"
                        :endpoint="endpoint" type="select" :options="bagStyleDropdownOptions"
                        @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Style -->

                    <!-- Skin -->
                    <div class="product-property">
                      <editable-field property="bag_skin_id" name="Skin" :initial-value="product.bag_skin_id"
                        :endpoint="endpoint" type="select" :options="bagSkinDropdownOptions"
                        @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Skin -->

                    <!-- Size -->
                    <div class="product-property">
                      <editable-field property="bag_size_id" name="Size" :initial-value="product.bag_size_id"
                        :endpoint="endpoint" type="select" :options="bagSizeDropdownOptions"
                        @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Size -->

                    <!-- Hardware -->
                    <div class="product-property">
                      <editable-field property="hardware_id" name="Hardware" :initial-value="product.hardware_id"
                        :endpoint="endpoint" type="select" :options="hardwareDropdownOptions"
                        @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Hardware -->

                    <!-- Status -->
                    <!-- <div class="product-property">
                      <editable-field
                        property="status"
                        name="Status"
                        :initial-value="product.status"
                        :endpoint="endpoint"
                        type="select"
                        :options="statusDropdownOptions"
                        @updated="fetchSilently"
                      ></editable-field>
                    </div> -->
                    <!-- / Status -->
                    <!-- If the bag was exported -->
                    <div class="product-property">
                      <editable-field v-if="product.status == 3" type="switch" property="is_bag_exported" name="If the bag was exported" :initial-value="product.is_bag_exported"
                                      :endpoint="endpoint" @updated="fetchSilently" on-label="Exported" off-label="Not Exported" :options="bagIsExportedOptions"></editable-field>
                    </div>
                    <!-- / If the bag was exported -->
                  </v-card-text>
                  <v-card-text :key="`pricing-properties-${silentUpdateKey}`">
                    <h5>Pricing</h5>

                    <!-- Currency -->
                    <div class="product-property">
                      <editable-field property="currency" name="Currency" :initial-value="product.currency"
                        :endpoint="endpoint" type="select" :options="currencyDropdownOptions" @updated="fetchSilently"
                        :cannot-edit="true"></editable-field>
                    </div>
                    <!-- / Currency -->
                    <!-- Purchase Price -->
                    <div class="product-property">
                      <editable-field property="purchase_price" name="Purchase Price"
                        :initial-value="product.purchase_price" :endpoint="endpoint" type="currency"
                        :currency="product.currency" @updated="fetchSilently"></editable-field>

                      <!-- <b>Purchase Price</b>
                      <br />
                      {{
                        product.purchase_price
                          ? formatCostInPence(
                              product.purchase_price,
                              product.currency
                            )
                          : "-"
                      }} -->
                    </div>
                    <!-- / Purchase Price -->
                    <!-- Expected Sale Price -->
                    <div class="product-property">
                      <editable-field property="expected_sale_price" name="Expected Sale Price"
                        :initial-value="product.expected_sale_price" :endpoint="endpoint" type="currency"
                        :currency="product.currency" @updated="fetchSilently"></editable-field>
                      <!-- <b>Expected Sale Price</b>
                      <br />
                      {{
                        product.expected_sale_price
                          ? formatCostInPence(
                              product.expected_sale_price,
                              product.currency
                            )
                          : "-"
                      }} -->
                    </div>
                    <!-- / Expected Sale Price -->
                    <!-- Sale Price -->
                    <div class="product-property">
                      <editable-field property="sale_price" name="Sale Price"
                        :initial-value="product.sale_price" :endpoint="endpoint" type="currency"
                        :currency="product.currency" @updated="fetchSilently"></editable-field>
                      <!-- <b>Sale Price</b>
                      <br />
                      {{
                        product.sale_price
                          ? formatCostInPence(
                              product.sale_price,
                              product.currency
                            )
                          : "-"
                      }} -->
                    </div>
                    <!-- / Sale Price -->
                    <!-- Consignor Profit -->
                    <div class="product-property">
                      <editable-field property="consignor_profit" name="Consignor Profit"
                        :initial-value="product.consignor_profit" :endpoint="endpoint" type="currency"
                        :currency="product.currency" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / Consignor Profit -->
                    <!-- MAIA Profit -->
                    <div class="product-property">
                      <editable-field property="maia_profit" name="MAIA Profit"
                        :initial-value="product.maia_profit" :endpoint="endpoint" type="currency"
                        :currency="product.currency" @updated="fetchSilently"></editable-field>
                    </div>
                    <!-- / MAIA Profit -->
                  </v-card-text>
                  <v-card-text :key="`date-properties-${silentUpdateKey}`">
                    <h5>Key Dates</h5>

                    <div class="product-property">
                      <editable-field property="purchased_at" name="Consignor Purchased On" :cannot-edit="product.status < 1"
                        :initial-value="product.purchased_at" :endpoint="endpoint" type="date"
                        @updated="fetchSilently"></editable-field>
                    </div>

                    <!-- <div class="product-property">
                      <editable-field property="" name="Consignor Assigned On" :cannot-edit="true"
                        :initial-value="product.investor_assigned_at" :endpoint="endpoint" type="date"
                        @updated="fetchSilently"></editable-field>
                    </div> -->

                    <div class="product-property">
                      <editable-field property="sold_at" name="Sold To End Client On" :cannot-edit="product.status < 3" :initial-value="product.sold_at"
                        :endpoint="endpoint" type="date" @updated="fetchSilently"></editable-field>
                    </div>
                  </v-card-text>
                  <!-- </base-card> -->
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- / Product Details -->

          <!-- Investor Details or Assign Investor -->
          <!-- <v-tab-item v-if="product.account_id" class="pt-4"> -->
          <v-tab-item class="pt-4">
            <investor-details @refresh="fetchSilently" @investor-removed="handleInvestorRemoved"
              :product="product"></investor-details>
          </v-tab-item>
          <!-- / Investor Details or Assign Investor -->

          <!-- Extend Offer -->
          <v-tab-item class="pt-4" v-if="!product.account_id">
            <extend-offer-table v-if="!(product.offers && product.offers.length)" @refresh="fetchSilently"
              :product="product"></extend-offer-table>

            <offers-extended v-else :product="product"></offers-extended>
          </v-tab-item>
          <!-- / Extend Offer -->

          <!-- Invoices -->
          <v-tab-item class="pt-4" v-if="product.account_id">
            <product-invoices-table @invoice-paid="fetchSilently" :product="product"></product-invoices-table>
          </v-tab-item>
          <!-- / Invoices -->
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import products from "../../../../api/products";
import ProductImagesGallery from "../../../../components/gallery/ProductImagesGallery.vue";
import Products from "../../../../mixins/Products";
import Display from "../../../../mixins/Display";
import Context from "../../../../mixins/Context";
import EditableField from "../../../../components/EditableField.vue";
import InvestorDetails from "../../../../components/products/InvestorDetails.vue";
import ExtendOfferTable from "../../../../components/products/ExtendOfferTable.vue";
import OffersExtended from "../../../../components/products/OffersExtended.vue";
import EditImagesButtonAndModal from "../../../../components/products/EditImagesButtonAndModal.vue";
import ChangeProductStatusButtonAndModals from "../../../../components/products/ChangeProductStatusButtonAndModals.vue";
import ProductInvoicesTable from "../../../../components/products/ProductInvoicesTable.vue";
import DeleteProductButton from "../../../../components/products/DeleteProductButton.vue";
export default {
  data() {
    return {
      isLoading: false,
      product: null,
      selectedTab: null,
      silentUpdateKey: 1,
    };
  },

  mixins: [Products, Display, Context],

  components: {
    ProductImagesGallery,
    EditableField,
    InvestorDetails,
    ExtendOfferTable,
    OffersExtended,
    EditImagesButtonAndModal,
    ChangeProductStatusButtonAndModals,
    ProductInvoicesTable,
    DeleteProductButton
  },

  created() {
    this.loadProduct();
  },

  methods: {
    goBack() {
      this.$router.push("/products");
    },

    handleProductDeleted() {
      this.goBack();
    },

    loadProduct() {
      let vm = this;
      vm.isLoading = true;
      vm.product = null;
      products
        .get(this.productId)
        .then((r) => {
          vm.isLoading = false;
          vm.product = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$toast.error("Product not found");
          vm.$router.push("/products");
        });
    },

    handleInvestorRemoved() {
      this.selectedTab = 0;
      this.fetchSilently();
    },

    fetchSilently() {
      let vm = this;
      // vm.isLoading = true;
      // vm.product = null;
      products
        .get(this.productId)
        .then((r) => {
          // vm.isLoading = false;
          vm.product = r.data;
          vm.$forceUpdate();
          vm.silentUpdateKey += 1;
        })
        .catch((e) => {
          console.log(e);
          // vm.isLoading = false;
          vm.$toast.error("Product not found");
          vm.$router.push("/products");
        });
    },
  },

  computed: {
    ...mapGetters("context", {
      context: "context",
    }),

    productId() {
      return this.$route.params.id;
    },

    endpoint() {
      return "products/" + this.productId;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.product) {
        return this.product.name;
      }
      return "Unknown";
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.inventory-show {
  padding: 20px;

  .welcome {
    margin-bottom: 20px;
    display: flex;

    @media screen and (min-width: 960px) {}

    .left {
      display: flex;
      justify-items: center;
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }

    h1 {
      margin-left: 10px;
    }
  }

  .product-property {
    padding-top: 15px;
    padding-bottom: 15px;

    border-bottom: 1px solid #efecf3 !important;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 5px !important;
      border-bottom: 0 !important;
    }
  }
}
</style>
