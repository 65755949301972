<template>
    <div class="delete-product-button">
        <v-btn @click.prevent="openDeleteModal"
         depressed dark color="danger">Delete</v-btn>

        <!-- Assign modal -->
        <div>
            <v-dialog v-model="attemptingToDelete" :persistent="attemptingToDelete" max-width="600px">
                <v-card v-if="attemptingToDelete">
                    <v-card-title class="text-h5 mb-4">Please Confirm</v-card-title>
                    <v-card-text>
                        <div class="mb-3">
                            Are you sure you want to delete <b>{{ product.name }}</b>?
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="isDeleting" color="red darken-1" text @click="attemptingToDelete = false">
                            Cancel
                        </v-btn>
                        <v-btn :disabled="isDeleting" :loading="isDeleting" color="primary darken-1" text
                            @click="attemptDelete">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <!-- / Assign modal -->
    </div>
</template>

<script>
import products from '../../api/products';
export default {
    props: ['product'],
    data() {
        return {
            attemptingToDelete: false,
            isDeleting: false
        }
    },

    methods: {
        openDeleteModal() {
            let vm = this;
            // if (vm.product.status >= 1) {
            //     vm.$toast.error('Cannot delete a product that belongs to a consignor');
            //     return false;
            // }
            this.attemptingToDelete = true;
        },

        attemptDelete() {
            let vm = this;

            vm.isDeleting = true;
            products.delete(this.product.id).then(r => {
                vm.isDeleting = false;
                vm.$toast.success('Product deleted successfully');
                vm.$emit('product-deleted');
            }).catch(e => {
                console.log(e);
                vm.isDeleting = false;
                vm.$toast.error('Error deleting product. Please try again.');
            })
        }
    }
}
</script>

<style scoped lang="scss">
.delete-product-button {
    display: inline-block;
}
</style>