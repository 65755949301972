<template>
  <div class="assign-investors-table">
    <v-data-table
      :headers="headers"
      :items="accounts"
      :options.sync="options"
      :server-items-length="totalAccounts"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.balance="{ item }">
        {{ formatCostInPence2dp(item.balance, item.currency) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          depressed
          @click.native.prevent="attemptToAssignInvestor(item)"
        >
          <!-- <v-icon small class="me-2">mdi-eye</v-icon> -->
          Assign</v-btn
        >
      </template>
    </v-data-table>

    <!-- Assign modal -->
    <div>
      <v-dialog
        v-model="show_modal"
        :persistent="isAssigning"
        max-width="600px"
      >
        <v-card v-if="attemptingToAssignToInvestor">
          <v-card-title class="text-h5 mb-4">Please Confirm</v-card-title>
          <v-card-text>
            <div class="mb-3">
              <b>Product</b> <br />
              {{ product.name }}
            </div>

            <div class="mb-3">
              <b>Consignor</b> <br />
              {{ attemptingToAssignToInvestor.user.name }}
            </div>

            <div>
              <b>Amount</b><br />
              {{
                formatCostInPence2dp(
                  this.product.purchase_price,
                  this.product.currency
                )
              }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isAssigning"
              color="red darken-1"
              text
              @click="attemptingToAssignToInvestor = null"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="isAssigning"
              :loading="isAssigning"
              color="primary darken-1"
              text
              @click="attemptAssign"
            >
              Assign Consignor & Raise Invoice
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- / Assign modal -->
  </div>
</template>

<script>
const _ = require("lodash");
import products from "../../api/products";
import Display from "../../mixins/Display";
import Forms from "../../mixins/Forms";
export default {
  props: ["product"],
  mixins: [Display, Forms],
  data() {
    return {
      totalAccounts: 0,
      accounts: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          value: "user.name",
        },
        // {
          // text: "Balance",
          // value: "balance",
        // },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],

      attemptingToAssignToInvestor: null,
      isAssigning: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  methods: {
    getFormParams() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      params.resultsPerPage = itemsPerPage;
      params.page = page;
      if (sortBy.length) {
        params.sortField = _.first(sortBy);
      }
      if (sortDesc.length) {
        let isDescending = _.first(sortDesc);
        params.sortOrder = isDescending ? "descend" : "ascend";
      }

      return params;
    },

    search() {
      this.loading = true;
      products
        .suggestInvestors(this.product.id, this.getFormParams())
        .then((r) => {
          this.accounts = r.data.data;
          this.totalAccounts = r.data.total;
          this.loading = false;
        });
    },

    attemptToAssignInvestor(investor) {
      let vm = this;
      vm.attemptingToAssignToInvestor = investor;
    },

    attemptAssign() {
      let vm = this;
      vm.isAssigning = true;
      products
        .assignInvestor(this.product.id, this.attemptingToAssignToInvestor.id)
        .then(() => {
          vm.$toast.success("Consignor assigned successfully");
          // vm.isAssigning = false;
          vm.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Error loading consignor details";
          }
          vm.$toast.error(error);
          vm.isAssigning = false;
        });
    },
  },
  computed: {
    show_modal: {
      get() {
        return this.attemptingToAssignToInvestor !== null;
      },
      set(val) {
        if (!val) {
          this.attemptingToAssignToInvestor = null;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.assign-investors-table {
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>