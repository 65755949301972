<template>
  <div class="px-6 py-6">
    <div class="loading" v-if="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <!-- Loaded -->
    <div v-if="!isLoading && account">
      <div class="px-5 py-2">
        <div class="property">
          <b>Name</b>
          {{ account.user.name }}
        </div>
        <div class="property">
          <b>Email</b>
          {{ account.user.email }}
        </div>
        <div class="property">
          <b>Phone</b>
          {{ account.user.phone_number ? account.user.phone_number : 'Not provided' }}
        </div>
        <div class="property">
          <v-btn depressed color="primary" @click.prevent="() => viewInvestor(account.user)">
            <v-icon small class="me-3">mdi-eye</v-icon>
            View Consignor
          </v-btn>

          <!-- <v-btn class="ma-3" depressed dark color="dark" @click.prevent="() => attemptMakeMine()">
            <v-icon small class="me-3">mdi-account-arrow-left-outline</v-icon>
            Make Mine
          </v-btn> -->

          <!-- <v-btn class="ma-3" v-if="canRemoveInvestor" depressed dark color="red" @click.prevent="() => attemptRemoveInvestor()">
            <v-icon small class="me-3">mdi-delete</v-icon>
            Remove Consignor
          </v-btn> -->

          <!-- Attempt to Remove -->
          <v-dialog v-model="attemptingToRemoveInvestor" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5 mb-4">Please Confirm Removal</v-card-title>
              <v-card-text>
                Are you sure you want to remove <b>{{ account ? account.user.name : "Unknown" }}</b> as a consignor?
                Their account will be refunded the purchase price.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="isRemovingInvestor" color="primary darken-1" text @click="attemptingToRemoveInvestor = null">
                  Cancel
                </v-btn>
                <v-btn :disabled="isRemovingInvestor" :loading="isRemovingInvestor" color="red darken-1" text
                  @click="removeInvestor">
                  Yes, Remove
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- / Attempt to Remove -->
        </div>
      </div>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import accounts from "../../../api/accounts";
import products from "../../../api/products";
import Forms from "../../../mixins/Forms";
const _ = require("lodash");
export default {
  props: ["product"],
  mixins: [Forms],
  data() {
    return {
      isLoading: false,
      account: null,

      attemptingToRemoveInvestor: false, 
      isRemovingInvestor: false
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    canRemoveInvestor() {
      return this.product.status < 3;
    }
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      vm.account = null;
      accounts
        .get(this.product.account_id)
        .then((r) => {
          vm.isLoading = false;
          vm.account = r.data;
        })
        .catch((e) => {
          console.log(e);
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Error loading consignor details";
          }
          vm.$toast.error(error);
          vm.isLoading = false;
        });
    },

    viewInvestor(investor) {
      this.$router.push('/investors/' + investor.id);
    },

    attemptRemoveInvestor() {
      this.attemptingToRemoveInvestor = true;
    },

    removeInvestor() {
      let vm = this;
      vm.isRemovingInvestor = true;
      products.removeInvestor(this.product.id).then(r => {
        vm.isRemovingInvestor = false;
        vm.attemptRemoveInvestor = false;
        vm.$toast.success('Consignor removed successfully');
        vm.$emit('investor-removed');
      }).catch(e => {
        vm.isRemovingInvestor = false;
        vm.$toast.error('Error removing consignor. Please try again.');
      });
    },

    attemptMakeMine() {
      alert('Need details of how this works');
    }
  },
};
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
  padding: 25px;
}

.property {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 20px;
  }

  b {
    display: block;
    margin-bottom: 5px;
  }
}
</style>