<template>
  <base-card class="product-image-card">
    <v-card-text
      class="d-flex justify-space-between align-center flex-wrap mb-4"
    >
      <div class="d-flex align-center">
        <v-img
          class="mr-md rounded mr-8"
          height="70"
          width="70"
          gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.05)"
          :src="getProductImageSrc(image)"
        />
        <div>
          <h2 href="#" class="text--primary h6 font-weight-medium mb-0 pb-0">
            Added {{ displayTimeAgo(image.created_at) }}
          </h2>
        </div>
      </div>
      <div>
        <v-btn
          @click.prevent="attemptDelete"
          color="red"
          dark
          small
          class="ma-2"
          depressed
          :loading="isDeleting"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </base-card>
</template>

<script>
import Dates from "../../../mixins/Dates";
import Forms from "../../../mixins/Forms";
import Products from "../../../mixins/Products";
import products from "../../../api/products";
const _ = require('lodash');
export default {
  props: ["product", "image"],
  mixins: [Products, Dates, Forms],
  data() {
    return {
      isDeleting: false
    }
  },
  methods: {
    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      products.deleteProductImage(this.product.id, this.image.id).then(r => {
        vm.isDeleting = false;
        vm.$toast.success('Image deleted successfully');
        vm.$emit('refresh');
      }).catch(e => {
        vm.isDeleting = false;
        console.log(e);
        let errors = vm.getErrorsAsArray(e);
        if (errors.length) {
          vm.$toast.error(_.first(errors));
        } else {
          vm.$toast.error('Error deleting image. Please try again.');
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.product-image-card {
  background-color: #f5f5f5 !important;
}
</style>