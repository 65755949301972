<template>
  <div>
    <v-btn @click.prevent="showModal = true" depressed dark color="primary">
      <v-icon class="me-2" small>mdi-plus</v-icon>
      Add New Images
    </v-btn>

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Add Images</v-card-title>
        <v-card-text>
          <uploader
            v-model="fileList"
            title="Upload new product images"
            :autoUpload="false"
          ></uploader>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            :disabled="isSaving"
            text
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving || fileList.length == 0"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptUpload"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Uploader from "vux-uploader-component";
import products from ".././.../../../../api/products";
import axios from 'axios';
export default {
  props: ["product"],
  components: { Uploader },
  data() {
    return {
      showModal: false,
      fileList: [],
      isSaving: false,
    };
  },
  watch: {
    showModal() {
      this.fileList = [];
    },
  },
  methods: {
    attemptUpload() {
      let vm = this;
      vm.isSaving = true;

      let reqs = [];
      _.each(this.fileList, (file) => {
        reqs.push(products.addProductImage(this.product.id, file.blob));
      });
      axios
        .all(reqs)
        .then((r) => {
          vm.$toast.success("Images uploaded successfully");
          vm.$emit("refresh");
          vm.isSaving = false;
          vm.showModal = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$toast.error("Error uploading images");
        });
    },
  },
};
</script>

<style>
</style>