<template>
  <div class="product-images-gallery">
    <div class="selected-image">
      <v-img
        contain
        aspect-ratio="1"
        :key="`selected-image-${selectedImage.id}`"
        max-height="40vh"
        max-width="100%"
        :src="getProductImageSrc(selectedImage)"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="image-selector" v-if="images.length > 1">
      <v-row justify="center">
        <v-col
          v-for="image in images"
          :key="image.id"
          class="d-flex child-flex align-center justify-center"
          cols="2"
        >
          <v-img
            @click.native.prevent="() => selectImage(image)"
            max-width="80%"
            :src="getProductImageSrc(image)"
            aspect-ratio="1"
            class="grey lighten-2 clickable"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
import Products from "../../mixins/Products";
export default {
  props: ["product"],
  mixins: [Products],
  data() {
    return {
      selectedImage: null,
    };
  },
  computed: {
    images() {
      return this.product.product_images;
    },
  },
  created() {
    if (this.product.product_images.length) {
      this.selectedImage = _.first(this.product.product_images);
    }
  },
  data() {
    return {};
  },
  methods: {
    selectImage(image) {
      this.selectedImage = image;
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>