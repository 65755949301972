<template>
  <div class="offers-extended">
    <div class="px-5 py-4">
      <div class="mb-7">
        <p>You have made the following offers:</p>
      </div>
    </div>
    <v-data-table
      class="elevation-1 offers-extended-table"
      :headers="headers"
      :items="product.offers"
    >
      <template v-slot:item.expires_at="{ item }">
        {{ formatDate(item.expires_at, "LLLL") }}
      </template>
      <template v-slot:item.investors="{ item }">
        <div
          class="py-3 me-3 display-inline"
          v-for="investorOffer in item.investor_offers"
          :key="investorOffer.id"
        >
          <v-btn
            small
            depressed
            @click.prevent="
              $router.push(`/investors/${investorOffer.account.user.id}`)
            "
          >
            {{ investorOffer.account.user.name }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Dates from "../../mixins/Dates";
export default {
  components: {},
  props: ["product"],
  mixins: [Dates],
  data() {
    return {
      headers: [
        {
          text: "Expires At",
          align: "expires_at",
          value: "expires_at",
        },
        {
          text: "Consignors",
          value: "investors",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.offers-extended {
  .display-inline {
    display: inline-block !important;
  }

  .offers-extended-table {
    tbody {
      tr:hover {
        background-color: transparent !important;
      }
    }
  }
}
</style>