<template>
  <div>
    <!-- Investor Assigned -->
    <div v-if="product.account_id">
      <assigned-investor-details @investor-removed="$emit('investor-removed')"
       :product="product"></assigned-investor-details>
    </div>
    <!-- / Investor Assigned -->

    <!-- No Investor Assigned -->
    <div v-if="!product.account_id">
      <assign-investors-table @refresh="$emit('refresh')"
       :product="product"></assign-investors-table>
    </div>
    <!-- / No Investor Assigneed -->
  </div>
</template>

<script>
import AssignInvestorsTable from './AssignInvestorsTable.vue'
import AssignedInvestorDetails from './InvestorDetails/AssignedInvestorDetails.vue'
export default {
  components: { AssignedInvestorDetails, AssignInvestorsTable },
  props: ['product']
}
</script>

<style>

</style>