<template>
  <div>
    <v-btn @click.prevent="showModal = true" depressed dark color="dark"
      >Mark As Sold</v-btn
    >

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Mark As Sold</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="attemptSave">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Sale Price"
                  :disabled="isSaving"
                  :prefix="currencyCodeToSymbol(product.currency)"
                  v-model="form.salePrice"
                  :rules="amountRules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            :disabled="isSaving"
            text
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import products from "../../../api/products";
import Forms from "../../../mixins/Forms";
import Display from "../../../mixins/Display";
const _ = require("lodash");
export default {
  props: ["product"],
  mixins: [Display, Forms],
  data() {
    return {
      showModal: false,
      isSaving: false,

      form: {
        salePrice: "",
      },

      amountRules: [
        (v) => !!v || "Sale price is required",
        (v) => /^\d+(\.\d+)?$/.test(v) || "Must be a number",
        // (v) =>
        //   (v && v.toString().split(".")[0].length <= 5) ||
        //   "No more than 5 digits before the decimal point",
        (v) =>
          (v && v.toString().split(".").length < 2) ||
          (v && v.toString().split(".")[1].length <= 2) ||
          "No more than 2 digits after the decimal point",
      ],
    };
  },

  methods: {
    attemptSave() {
      let vm = this;

      let isValid = this.$refs["form"].validate();
      if (!isValid) {
        return false;
      }

      vm.isSaving = true;
      products
        .markAsSold(this.product.id, {
          sale_price: Number(this.form.salePrice) * 100,
        })
        .then(() => {
          vm.isSaving = false;
          vm.$toast.success("Marked as sold successfully");
          vm.showModal = false;
          vm.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          let errors = vm.getErrorsAsArray(e);
          if (errors.length) {
            vm.$toast.error(_.first(errors));
          } else {
            vm.$toast.error("Error marking as sold");
          }
        });
    },
  },
};
</script>

<style>
</style>