<template>
  <div style="display: inline-block;">
    <v-btn @click.prevent="showModal = true" depressed dark color="blue"
      >Edit Images</v-btn
    >

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Edit Images</v-card-title>

        <v-card-text>
          <product-image-card
            class="mb-2"
            v-for="(image, i) in product.product_images"
            :key="i"
            :product="product"
            :image="image"
            @refresh="$emit('refresh')"
          ></product-image-card>

          <div class="pt-3">
            <add-product-images-button-and-modal
              :product="product"
              @refresh="$emit('refresh')"
            ></add-product-images-button-and-modal>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="showModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductImageCard from "./EditImagesButtonAndModal/ProductImageCard.vue";
import AddProductImagesButtonAndModal from "./EditImagesButtonAndModal/AddProductImagesButtonAndModal.vue";
export default {
  props: ["product"],
  components: { ProductImageCard, AddProductImagesButtonAndModal },
  data() {
    return {
      showModal: false,
      headers: [
        {
          text: "Image",
          align: "start",
          value: "image",
        },
        { text: "Actions", value: "actions", sortable: false, width: "230px" },
      ],
    };
  },
};
</script>

<style>
</style>