<template>
  <div>
    <v-btn @click.prevent="showModal = true" depressed dark color="dark"
      >Mark As In Stock</v-btn
    >

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Mark As In Stock</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="attemptSave">
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Location"
                  v-model="form.locationId"
                  :items="locationOptions"
                  class="form-control"
                  required
                  :rules="locationRules"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            :disabled="isSaving"
            text
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import products from "../../../api/products";
import Forms from "../../../mixins/Forms";
import Display from "../../../mixins/Display";
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  props: ["product"],
  mixins: [Display, Forms],
  data() {
    return {
      showModal: false,
      isSaving: false,

      form: {
        locationId: null,
      },

      locationRules: [(v) => !!v || "Location is required"],
    };
  },

  computed: {
    ...mapGetters("context", {
      context: "context",
    }),

    locationOptions() {
      if (this.context && this.context.locations) {
        return _.map(this.context.locations, (location) => {
          return {
            value: location.id,
            text: location.name,
          };
        });
      }
      return [];
    },
  },

  methods: {
    attemptSave() {
      let vm = this;

      let isValid = this.$refs["form"].validate();
      if (!isValid) {
        return false;
      }

      vm.isSaving = true;
      products
        .markProductAsInStock(this.product.id, {
          location_id: this.form.locationId,
        })
        .then(() => {
          vm.isSaving = false;
          vm.$toast.success("Marked as in stock");
          vm.showModal = false;
          vm.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          let errors = vm.getErrorsAsArray(e);
          if (errors.length) {
            vm.$toast.error(_.first(errors));
          } else {
            vm.$toast.error("Error marking as in stock");
          }
        });
    },
  },
};
</script>

<style>
</style>