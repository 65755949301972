import axios from 'axios';

export default {
    markAsPaid(invoiceId) {
        return axios.post(window.API_BASE + '/invoices/' + invoiceId + '/mark-as-paid');
    },

    search(params) {
        return axios.post(window.API_BASE + '/invoices/search', params);
    }
}