import moment from "moment"

export default {
  methods: {
    getLastActivityHuman(chat, allowDatesInFuture = false) {
      let m = window.moment(chat.lastActivity)
      if (m.isValid()) {
        let str = m.fromNow();
        if (allowDatesInFuture) {
          return str;
        } else {
          return str.replace('in a few seconds', 'a few seconds ago');
        }
      }
      return '-'
    },

    displayTimeAgo(datetime, allowDatesInFuture = false) {
      let str = moment(datetime).fromNow();
      if (allowDatesInFuture) {
        return str;
      } else {
        return str.replace('in a few seconds', 'a few seconds ago');
      }
    },

    formatDate(datetime, format = 'LLLL') {
      return moment(datetime).format(format);
    },

    invoiceDate(datetime, format = 'Do MMMM YYYY') {
      return moment(datetime).format(format);
    }
  }
}
