<template>
  <div style="display: inline-block">
    <!-- <mark-as-purchased-button-and-modal
      v-if="product.status == 0"
      @refresh="refresh"
      :product="product"
    ></mark-as-purchased-button-and-modal> -->

    <!-- <mark-as-in-stock-button-and-modal
      v-if="product.status == 1"
      @refresh="refresh"
      :product="product"
    >
    </mark-as-in-stock-button-and-modal> -->

    <mark-as-sold-button-and-modal
      v-if="product.status == 2"
      @refresh="refresh"
      :product="product"
    >
    </mark-as-sold-button-and-modal>
  </div>
</template>

<script>
import MarkAsInStockButtonAndModal from "./ChangeProductStatusButtonAndModals/MarkAsInStockButtonAndModal.vue";
import MarkAsPurchasedButtonAndModal from "./ChangeProductStatusButtonAndModals/MarkAsPurchasedButtonAndModal.vue";
import MarkAsSoldButtonAndModal from "./ChangeProductStatusButtonAndModals/MarkAsSoldButtonAndModal.vue";
export default {
  props: ["product"],
  components: {
    MarkAsPurchasedButtonAndModal,
    MarkAsInStockButtonAndModal,
    MarkAsSoldButtonAndModal,
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style>
</style>