import { render, staticRenderFns } from "./ProductImageCard.vue?vue&type=template&id=25711b4a&scoped=true&"
import script from "./ProductImageCard.vue?vue&type=script&lang=js&"
export * from "./ProductImageCard.vue?vue&type=script&lang=js&"
import style0 from "./ProductImageCard.vue?vue&type=style&index=0&id=25711b4a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25711b4a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCardText,VIcon,VImg})
