<template>
  <div class="product-invoices-table">
    <v-data-table :headers="headers" :items="invoices" :options.sync="options" :server-items-length="totalInvoices"
                  :loading="loading" v-model="selected" class="elevation-1">
      <template v-slot:item.total="{ item }">
        {{ formatCostInPence2dp(item.total, item.currency) }}
      </template>
      <template v-slot:item.paid="{ item }">
        <v-icon class="success--text" v-if="item.paid">mdi-check</v-icon>
        <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="!item.paid" small depressed @click.native.prevent="() => attemptMarkInvoiceAsPaid(item)">
          <v-icon small class="me-2">mdi-check</v-icon>
          Mark As Paid
        </v-btn>

        <v-btn small depressed @click="() => handleButtonClick(item)">
          <v-icon small class="me-2 view-pdf">mdi-check</v-icon>
          View PDF Invoice
        </v-btn>

        <v-btn v-if="product.status == 3" small depressed @click="() => handleButtonClick(item, 'reverse')" class="reverse-button">
          <v-icon small class="me-2 view-pdf">mdi-check</v-icon>
          PDF Reverse Invoice
        </v-btn>

      </template>
    </v-data-table>

    <!-- Mark as paid modal -->
    <v-dialog v-model="showMarkAsPaidModal" max-width="400">
      <v-card>
        <v-card-title class="text-h5 mb-4"> Please Confirm</v-card-title>

        <v-card-text>
          Are you sure you want to mark this invoice as paid?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="(attemptingToMarkInvoiceAsPaid = null)">
            Cancel
          </v-btn>

          <v-btn color="primary darken-1" text @click="confirmMarkAsPaid">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Mark as paid modal -->
  </div>
</template>

<script>
import * as path from "path";

const _ = require("lodash");
import products from "../../api/products";
import investors from "../../api/investors";
import invoices from "../../api/invoices";
import Display from "../../mixins/Display";
import Dates from "../../mixins/Dates";
import Forms from "../../mixins/Forms";
import {degrees, PDFDocument, rgb, StandardFonts, PDFFont} from 'pdf-lib';
import fs from "fs";
import fontkit from '@pdf-lib/fontkit';
import axios from 'axios';

export default {
  props: ["product"],
  mixins: [Display, Dates, Forms],
  data() {
    return {
      totalInvoices: 0,
      invoices: [],
      loading: true,
      options: {},
      selected: [],
      headers: [
        {
          text: "Description",
          align: "start",
          value: "description",
        },
        {
          text: "Total",
          value: "total"
        },
        {
          text: "Paid",
          value: "paid"
        },
        {text: "Actions", value: "actions", sortable: false, width: "320px"},
        // {
        // text: "Balance",
        // value: "balance",
        // },
      ],
      isSaving: false,
      attemptingToMarkInvoiceAsPaid: null
    };
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  computed: {
    showMarkAsPaidModal: {
      get() {
        return this.attemptingToMarkInvoiceAsPaid !== null;
      },
      set(val) {
        if (!val) {
          this.attemptMarkInvoiceAsPaid = null;
        }
      }
    }
  },
  methods: {
    attemptMarkInvoiceAsPaid(invoice) {
      this.attemptingToMarkInvoiceAsPaid = invoice;
    },

    confirmMarkAsPaid() {
      let vm = this;
      vm.isSaving = true;
      invoices.markAsPaid(this.attemptingToMarkInvoiceAsPaid.id).then(r => {
        vm.attemptingToMarkInvoiceAsPaid = null;
        vm.$toast.success('Invoice marked as paid successfully');
        vm.search();
        vm.$emit('invoice-paid');
      }).catch(e => {
        console.log(e);
        let errors = vm.getErrorsAsArray(e);
        let error;
        if (errors.length) {
          error = _.first(errors);
        } else {
          error = "Error marking invoice as paid";
        }
        vm.$toast.error(error);
        vm.attemptingToMarkInvoiceAsPaid = null;
      });
    },

    async viewStraightforwardInvoicePdf(invoice, url) {
      // Fetch the PDF using axios.
      const response = await axios.get(url, {responseType: 'arraybuffer'});
      const existingPdfBytes = response.data;

      // Load the PDF using pdf-lib.
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);

      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // get array buffer of font
      const fontUrl = '/CambriaFont.ttf'
      const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
      // embed array buffer of font into PDF
      const customFont = await pdfDoc.embedFont(fontBytes);

      // Modify the PDF.
      const firstPage = pdfDoc.getPages()[0];
      const {height} = firstPage.getSize();
      const date = this.invoiceDate(invoice.created_at)
      const total = this.formatCostInPence2dp(invoice.total, invoice.currency)
      const investor = await investors.get( this.product.account.user_id);




      firstPage.drawText(investor.data.name, {
        x: 61,
        y: 672,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.address_line_one, {
        x: 61,
        y: 655,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.city, {
        x: 61,
        y: 645,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.postcode.toUpperCase(), {
        x: 61,
        y: 635,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });


      firstPage.drawText(this.product.stock_number.toString(), {
        x: 450,
        y: 686,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(date.toString(), {
        x: 419,
        y: 662,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText('1', {
        x: 91,
        y: 526,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      const max_description_chars = 66;
      const margin_px = 10;

      const invoice_description = invoice.description.toString();
      const invoice_chunks = invoice_description.match(new RegExp('.{1,' + max_description_chars + '}', 'g'));

      let linues_counter = 0;
      for (const invoice_chunk of invoice_chunks) {
        firstPage.drawText(invoice_chunk.trim(), {
          x: 136,
          y: 526 - (margin_px * linues_counter),
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        linues_counter++;
      }

      firstPage.drawText(total.toString(), {
        x: 485,
        y: 526,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      // Total
      firstPage.drawText(total.toString(), {
        x: 485,
        y: 280,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      if(invoice.paid == 1){
        firstPage.drawText('PAID', {
          x: 135,
          y: height / 2 + 100,
          size: 150,
          font: customFont,
          color: rgb(0.8, 0.8, 0.8),
          opacity: 0.3,
          rotate: degrees(-45),
        })
      }



      // Save the modified PDF to a variable (`pdfBytes`).
      const pdfBytes = await pdfDoc.save();

      // Now, you can use `pdfBytes` for any additional operations (e.g. downloading the modified PDF).
      return pdfBytes;
    },
    async viewReverseInvoicePdf(invoice, url) {
      // Fetch the PDF using axios.
      const response = await axios.get(url, {responseType: 'arraybuffer'});
      const existingPdfBytes = response.data;

      // Load the PDF using pdf-lib.
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);

      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // get array buffer of font
      const fontUrl = '/CambriaFont.ttf'
      const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
      // embed array buffer of font into PDF
      const customFont = await pdfDoc.embedFont(fontBytes);

      // Modify the PDF.
      const firstPage = pdfDoc.getPages()[0];
      const {height} = firstPage.getSize();
      const date = this.invoiceDate(this.product.sold_at)
      const product = (await products
        .get(invoice.product_id)).data;

      const purchase_price = this.formatCostInPence2dp(product.purchase_price, invoice.currency)
      const consignor_profit = this.formatCostInPence2dp(product.consignor_profit, invoice.currency)
      const total = this.formatCostInPence2dp(product.purchase_price + product.consignor_profit, invoice.currency)
      const investor = await investors.get( this.product.account.user_id);
      let exported = 0;
      if (product.is_bag_exported === 1) {
        exported = 1;
      }
      let sales_number = ''
      if (this.product.sales_number) {
        sales_number = this.product.sales_number.toString()
      }

      firstPage.drawText(investor.data.name, {
        x: 350,
        y: 610,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.address_line_one, {
        x: 350,
        y: 595,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.city, {
        x: 350,
        y: 580,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.postcode.toUpperCase(), {
        x: 350,
        y: 565,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });


      firstPage.drawText(this.product.account.user_id + "/" + sales_number, {
        x: 121,
        y: 657,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });
      if (exported) {
        firstPage.drawText("EXPORTED", {
          x: 136,
          y: 386,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      } else {
        firstPage.drawText("VAT: THIS INVOICE DOES NOT GIVE RISE TO INPUT CREDIT", {
          x: 136,
          y: 346,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),

        });
        firstPage.drawText("OF VAT UNDER THE UK’s MARGIN RULE", {
          x: 136,
          y: 336,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),

        });

      }
      if (exported) {
        firstPage.drawText("VAT:", {
          x: 421,
          y: 309,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText("0%", {
          x: 485,
          y: 309,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
      firstPage.drawText(date.toString(), {
        x: 91,
        y: 673,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });
      firstPage.drawText('1', {
        x: 91,
        y: 426,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });
      firstPage.drawText('1', {
        x: 91,
        y: 406,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(this.product.name.toString(), {
        x: 136,
        y: 446,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText("REFUND STOCK BAG (INVOICE: " + this.product.stock_number + ")", {
        x: 136,
        y: 426,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText("COMMISSION FROM SALES (SOLD INVOICE: " + this.product.sales_number + ")", {
        x: 136,
        y: 406,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(purchase_price.toString(), {
        x: 485,
        y: 426,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(consignor_profit.toString(), {
        x: 485,
        y: 406,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      // Total
      firstPage.drawText(total.toString(), {
        x: 485,
        y: 289,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      // Save the modified PDF to a variable (`pdfBytes`).
      const pdfBytes = await pdfDoc.save();

      // Now, you can use `pdfBytes` for any additional operations (e.g. downloading the modified PDF).
      return pdfBytes;
    },
    async handleButtonClick(invoice, type = 'straightforward') {
      try {
        const invoice_band_type = !!invoice.band_id_at_the_creation ? 'maia' : 'luxury';
        const pdf_template_url = `/Invoice_template_${invoice_band_type}_${type}.pdf`;
        const pdfBytes = type === 'straightforward' ? await this.viewStraightforwardInvoicePdf(invoice, pdf_template_url): await this.viewReverseInvoicePdf(invoice, pdf_template_url);
        this.downloadPdf(pdfBytes, invoice);
      } catch (error) {
        console.error('Error modifying PDF:', error);
      }
    },
    downloadPdf(pdfBytes, invoice) {
      const blob = new Blob([pdfBytes], {type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = '/invoice_' + this.product.stock_number.toString() + '.pdf';
      link.click();
    },


    getFormParams() {
      let params = {};

      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      params.resultsPerPage = itemsPerPage;
      params.page = page;
      if (sortBy.length) {
        params.sortField = _.first(sortBy);
      }
      if (sortDesc.length) {
        let isDescending = _.first(sortDesc);
        params.sortOrder = isDescending ? "descend" : "ascend";
      }

      return params;
    },

    search() {
      this.loading = true;
      products
        .searchInvoices(this.product.id, this.getFormParams())
        .then((r) => {
          this.invoices = r.data.data;
          this.totalInvoices = r.data.total;
          this.loading = false;
        });
    }
  },
};
</script>

<style lang="scss">
.product-invoices-table {
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}

.reverse-button {
  margin-top: 10px;
}

//   .expires-at-b-label {
//     display: block;
//     margin-bottom: 10px;
//   }
</style>
