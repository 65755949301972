<template>
  <div class="extend-offer-table">
    <v-data-table
      :headers="headers"
      :items="accounts"
      :options.sync="options"
      :server-items-length="totalAccounts"
      :loading="loading"
      show-select
      v-model="selected"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!selected.length"
            @click.prevent="showModal = true"
            depressed
            >Extend Offer</v-btn
          >
        </v-toolbar>
      </template>

      <template v-slot:item.balance="{ item }">
        {{ formatCostInPence2dp(item.balance, item.currency) }}
      </template>
    </v-data-table>

    <!-- Assign modal -->
    <div>
      <v-dialog v-model="showModal" :persistent="isSaving" max-width="600px">
        <v-card>
          <v-card-title class="text-h5 mb-4"
            >Extend Offer ({{ selected.length }}
            {{ selected.length > 1 ? "Consignors" : "Consignor" }} Selected)
          </v-card-title>
          <v-card-text> </v-card-text>
          <v-card-text>
            <b class="expires-at-b-label">Expires At</b>
            <vue-datepicker
              :disabled="isSaving"
              id="expiresAt"
              type="datetime"
              format="YYYY-MM-DD HH:mm"
              v-model="offerExpiresAt"
            ></vue-datepicker>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isSaving"
              color="red darken-1"
              text
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="isSaving || !canSave"
              :loading="isSaving"
              color="primary darken-1"
              text
              @click="attemptSave"
            >
              Extend Offer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- / Assign modal -->
  </div>
</template>

<script>
const _ = require("lodash");
import products from "../../api/products";
import Display from "../../mixins/Display";
import Forms from "../../mixins/Forms";
export default {
  props: ["product"],
  mixins: [Display, Forms],
  data() {
    return {
      totalAccounts: 0,
      accounts: [],
      loading: true,
      options: {},
      selected: [],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "user.name",
        },
        // {
          // text: "Balance",
          // value: "balance",
        // },
      ],

      showModal: false,
      offerExpiresAt: null,
      isSaving: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  computed: {
    canSave() {
      return this.offerExpiresAt;
    },
  },
  methods: {
    getFormParams() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      params.resultsPerPage = itemsPerPage;
      params.page = page;
      if (sortBy.length) {
        params.sortField = _.first(sortBy);
      }
      if (sortDesc.length) {
        let isDescending = _.first(sortDesc);
        params.sortOrder = isDescending ? "descend" : "ascend";
      }

      return params;
    },

    search() {
      this.loading = true;
      products
        .suggestInvestors(this.product.id, this.getFormParams())
        .then((r) => {
          this.accounts = r.data.data;
          this.totalAccounts = r.data.total;
          this.loading = false;
        });
    },

    attemptSave() {
      let vm = this;

      vm.isSaving = true;
      products
        .extendOffer(
          this.product.id,
          _.map(this.selected, "id"),
          this.offerExpiresAt
        )
        .then(() => {
          // vm.isSaving = false;
          vm.$toast.success("Offer extended successfully");
          vm.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$toast.error("Error extending offer");
        });
    }
  },
};
</script>

<style lang="scss">
.extend-offer-table {
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}

.expires-at-b-label {
  display: block;
  margin-bottom: 10px;
}
</style>